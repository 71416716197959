.main-container {
  max-height: 90vh;
}

.mascot-logo-title {
  height:50px; /* 10% of the viewport height */
  width: auto; /* Maintain the aspect ratio */
  display: block; /* To center the logo */
  margin: 0 auto; /* Auto margins center block elements horizontally */
  margin-top: 20px;
  margin-bottom: 0px;
}

.link-tree-list {
  max-height: 50vh; /* Adjust the height as needed */
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.1); /* Translucent background */
  border-radius: 8px; /* Rounded corners */
  max-width: 600px;
  margin: auto;
  align-self: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ddd; /* Add border */
  background-color: #4CAF50; /* Default background */
  color: white;
  border-radius: 5px; /* Rounded corners */
  transition: all 0.3s ease; /* Smooth transition for hover and disabled state */
}

.pagination button:hover:not(:disabled) {
  background-color: #367c39; /* Darker shade on hover */
}

.pagination button:disabled {
  background-color: #9e9e9e; /* Dim background when disabled */
  color: #cfcfcf; /* Dim text when disabled */
  border-color: #9e9e9e; /* Dim border when disabled */
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
}

.page-item {
  padding: 10px;
  width: 70%;
  margin: 5px auto; /* Auto margin for horizontal centering */
  background: rgba(255, 255, 255, 0.4); /* Semi-transparent white background */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
  display: flex; /* Use flexbox to center content */
  justify-content: space-between; /* Space content between start and end */
  align-items: center;
}

.page-item:hover {
  background-color: rgba(255, 255, 255, 0.6); /* Slightly darker on hover */
}

.page-item a {
  color: #333; /* Text color */
  text-decoration: none; /* Removes underline */
}

.page-item button {
  margin-left: 10px;
}

.page-item span {
  font-size: 0.9rem;
  color: #555;
}
