/* LinkTreePage.css */

/* Add the mascot logo style */
.mascot-logo-title {
  height:18vh; /* 10% of the viewport height */
  width: auto; /* Maintain the aspect ratio */
  display: block; /* To center the logo */
  margin: 0 auto; /* Auto margins center block elements horizontally */
}

/* Style for the container */
.page-container {
  padding: 20px;
  text-align: center; /* Center align the content */
}

/* Style for the page title */
.page-title {
  color: rgb(244, 235, 223); /* Set the title color to white */
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 25px;
}

/* Style for the links container with a glass-like effect */
.links-container {
  max-width: 50%;
  margin: auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect for the background */
  border-radius: 10px; /* Optional: if you want rounded corners */
}

/* Style for individual link items */
.link-item {
  margin: 10px 0;
  display: block; /* if you want the link to be block-level */
}

/* Style for the link anchors */
.link-item a {
  padding: 10px;
  display: block; /* makes the entire area clickable */
  text-decoration: none; /* Removes underline from links */
  color: rgb(244, 235, 223); /* Sets the text color for links; choose what fits your design */
  font-size: 20px;
  background: rgba(21, 35, 56, 0.2); /* Semi-transparent background */
  border-radius: 10px;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.link-item a:hover {
  background-color: rgba(255, 255, 255, 0.6); /* Slightly darker on hover */
  border-radius: 10px;
  color: rgb(47, 47, 47);
}

/* You may need to adjust the colors to fit your design better */
