/* Home.css */

.home-background {
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100vh; /* Takes the full viewport height */
  width: 100vw; /* Takes the full viewport width */
  margin: 0; /* Resets any default margins */
  padding: 0; /* Resets any default padding */
}

.mascot-logo {
  height: 30vh; /* 30% of the viewport height */
  width: auto; /* Maintain the aspect ratio */
  position: absolute; /* Position the mascot logo absolutely */
  top: calc(50% - 17vh); /* Move it up by half of its own height */
}