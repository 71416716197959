/* Import the Google Font */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

/* Reset margin and padding for all elements */
* {
  /* margin: 0; */
  /* padding: 0; */
  /* box-sizing: border-box; */
  /* Apply the font family */
  font-family: 'Fredoka One', cursive;
}

/* Add the background image to the body or main app container */
body, .app {
  background-image: url('../public/Pattern.png'); /* Correct path assuming Pattern.png is in the public directory */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  overflow: hidden; /* Hide any overflow */
  min-height: 100vh; /* Ensure it covers at least the full viewport height */
  width: 100vw; /* Ensure it covers the full viewport width */
  text-align: center;
}


/* Style for the button inside the link item for deletion */
.link-item button {
  padding: 5px 10px;
  margin-left: 10px; /* Spacing between link text and delete button */
  background-color: #f44336; /* Red color for delete button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Style for the form and its elements */
form {
  margin-bottom: 20px;
}

input[type="text"],
input[type="url"],
button {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

button {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

/* Style for the list of link trees */
.link-tree-list {
  margin-top: 30px;
}

.link-tree-list h2 {
  margin-bottom: 20px;
}

/* Individual page item in the list of link trees */
.page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.page-item a {
  margin-right: 15px;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.page-item button {
  margin-right: 15px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.page-item span {
  font-size: 0.9rem;
  color: #555;
}

/* Make sure this is unique to avoid styling other buttons the same */
.create-link-tree-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

/* Add some responsive styling */
@media (max-width: 768px) {
  .link-item, .page-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .link-item a,
  .link-item button,
  .page-item a,
  .page-item button,
  .page-item span {
    /* width: 100%; */
    text-align: left;
    margin: 5px 0;
  }
}
